import React, { MouseEventHandler } from 'react';
import { pushTrackingEvent } from '@freshheads/javascript-essentials/build/utilities/dataLayer';

type Props = {
    children: React.ReactNode;
};

const BookPreviewLink: React.FC<Props> = ({ children }) => {
    const url = '/inkijkexemplaar.pdf';

    const onClick: MouseEventHandler = () => {
        pushTrackingEvent('book_preview', 'click');
    };

    return (
        <a href={url} onClick={onClick} className="book-preview">
            {children}
        </a>
    );
};

export default BookPreviewLink;
