import React from 'react';

type Props = {
    children: React.ReactNode;
};

const AuthorContactOptions: React.FC<Props> = ({ children }) => (
    <div className="authors__contact-options">{children}</div>
);

export default AuthorContactOptions;
