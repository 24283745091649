import { useState } from 'react';

type Result = {
    isOpen: boolean;
    toggle: () => void;
    open: () => void;
    close: () => void;
};

export default function useToggleOpenClose(initialValue: boolean): Result {
    const [isOpen, setIsOpen] = useState<boolean>(initialValue);

    const toggle = () => {
        setIsOpen((currentValue) => !currentValue);
    };

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    return { isOpen, toggle, open, close };
}
