import React from 'react';
import createClassName from 'classnames';
import { Link } from 'react-router-dom';

type Props = {
    children: React.ReactNode;
    path: string;
    anchor?: boolean;
    className?: string;
};

const LinkButton: React.FC<Props> = ({
    path,
    anchor = false,
    className: additionalClassName,
    children,
}) => {
    const className = createClassName(
        'button',
        {
            'button--anchor': anchor,
        },
        additionalClassName
    );

    return (
        <Link to={path} className={className}>
            {children}
        </Link>
    );
};

export default LinkButton;
