import React from 'react';
import Image from '../../../../../images/authors.png';

const AuthorsImage: React.FC = () => (
    <img
        src={Image}
        alt="Wout Withagen en Joost Gielen"
        className="authors__image"
    />
);

export default AuthorsImage;
