import React from 'react';
import FormSubmitButton from '../../primitives/form/FormSubmitButton';
import { ReactComponent as ArrowRightIcon } from '../../../../../images/icons/arrow-right.svg';
import { ReactComponent as EnvelopeIcon } from '../../../../../images/icons/envelope.svg';
import { TrackingEventName } from '../NewsletterSubscription';

const NewsletterSubscriptionSubmitButton: React.FC = () => (
    <FormSubmitButton
        trackEvent={TrackingEventName}
        className="newsletter-subscription__button"
    >
        <ArrowRightIcon className="newsletter-subscription__button-icon button__icon" />
        <EnvelopeIcon className="newsletter-subscription__button-icon--from-md button__icon" />
        <span className="newsletter-subscription__button-text">Aanmelden</span>
    </FormSubmitButton>
);

export default NewsletterSubscriptionSubmitButton;
