import { generateRoute } from '../utility/urlGenerator';

export const createPageDetailApiPath = (slug: string): string =>
    generateRoute('api_page_detail', { slug });

export const createPageIndexApiPath = (withIdentifiers: boolean): string =>
    generateRoute('api_page_index', {
        identifiers: withIdentifiers,
    });

export const createPreorderSubmitApiPath = (): string =>
    generateRoute('api_preorder_submit');

export const createContactRequestSubmitApiPath = (): string =>
    generateRoute('api_contact_request_submit');

export const createNewsletterSubscriptionSubmitApiPath = (): string =>
    generateRoute('api_newsletter_subscription_submit');
