import React, { Suspense, lazy } from 'react';

const ContactLoader: React.FC = lazy(() =>
    import(/* webpackChunkName: "contact", webpackPrefetch: true */ './Contact')
);

const LazyLoadingContact: React.FC = () => (
    <Suspense fallback="">
        <ContactLoader />
    </Suspense>
);

export default LazyLoadingContact;
