import React from 'react';
import MainNavigation from '../../mainNavigation/MainNavigation';

const Header: React.FC = () => (
    <header className="header">
        <MainNavigation />
    </header>
);

export default Header;
