import React from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    position: 'right' | 'left';
};

const AuthorInfo: React.FC<Props> = ({ children, position }) => {
    const className = createClassName('authors__author-info', {
        'authors__author-info--left': position === 'left',
        'authors__author-info--right': position === 'right',
    });

    return <div className={className}>{children}</div>;
};

export default AuthorInfo;
