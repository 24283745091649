import React from 'react';

type Props = {
    name: string;
};

const AuthorsName: React.FC<Props> = ({ name }) => (
    <div className="authors__name">{name}</div>
);

export default AuthorsName;
