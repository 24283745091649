import React from 'react';
import { FormikValues } from 'formik';
import createClassName from 'classnames';

type InheritedProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'name' | 'id'
>;

interface Props<V extends FormikValues> extends InheritedProps {
    name: Extract<keyof V, string>;
    id: Extract<keyof V, string>;
    type?: 'text' | 'email';
}

export default function FormTextInput<V extends FormikValues>({
    type = 'text',
    name,
    className: additionalClassName,
    ...otherProps
}: Props<V>): JSX.Element {
    const className = createClassName('form-field', additionalClassName);

    return (
        <input {...otherProps} name={name} type={type} className={className} />
    );
}
