import React from 'react';

type Props = {
    children: React.ReactNode;
};

const AuthorsImageHolder: React.FC<Props> = ({ children }) => (
    <div className="authors__image-holder">{children}</div>
);

export default AuthorsImageHolder;
