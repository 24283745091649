import { useFormik, FormikTouched, FormikErrors } from 'formik';
import {
    FormValues,
    OnValidSubmitHandler,
} from '../components/NewsletterSubscriptionForm';

type Result = {
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (event: React.ChangeEvent<any>) => void;
    values: FormValues;
    touched: FormikTouched<FormValues>;
    errors: FormikErrors<FormValues>;
};

export default function useNewsletterSubscriptionFormHandlers(
    onValidSubmit: OnValidSubmitHandler
): Result {
    const { handleSubmit, handleChange, values, touched, errors } = useFormik<
        FormValues
    >({
        initialValues: {
            emailAddress: '',
        },
        onSubmit: onValidSubmit,
        validate: (values: FormValues) => {
            const errors: FormikErrors<FormValues> = {};
            const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

            if (!values.emailAddress) {
                errors.emailAddress = 'Dit veld is verplicht';
            } else if (!emailRegex.test(values.emailAddress)) {
                errors.emailAddress = 'Vul een geldig e-mailadres in';
            }

            return errors;
        },
    });

    return { handleSubmit, handleChange, values, touched, errors };
}
