import { useState } from 'react';
import { OnValidSubmitHandler } from '../components/NewsletterSubscriptionForm';
import { executePostRequest } from '../../../api/client';
import { createNewsletterSubscriptionSubmitApiPath } from '../../../routing/urlGenerator/apiUrlGenerator';
import { logError } from '../../../utilities/logging';
import { pushTrackingEvent } from '../../../utilities/trackingUtilities';
import { TrackingEventName } from '../NewsletterSubscription';

export enum Status {
    Open,
    Submitting,
    Error,
    Success,
}

type Result = {
    onValidSubmit: OnValidSubmitHandler;
    status: Status;
};

export default function usePushNewsletterSubscriptionToServerOnFormValid(): Result {
    const [status, setStatus] = useState<Status>(Status.Open);

    const onValidSubmit: OnValidSubmitHandler = ({ emailAddress }) => {
        setStatus(Status.Submitting);

        const [promise] = executePostRequest<void>(
            createNewsletterSubscriptionSubmitApiPath(),
            { emailAddress }
        );

        promise
            .then(() => {
                pushTrackingEvent(TrackingEventName, 'conversion');

                setStatus(Status.Success);
            })
            .catch((error) => {
                logError(error, {
                    action: 'Push newsletter subscription to server',
                });

                setStatus(Status.Error);
            });
    };

    return { onValidSubmit, status };
}
