import React from 'react';
import createClassName from 'classnames';

type Props = {
    className?: string;
    leftAligned?: boolean;
    children: React.ReactNode;
};

const Intro: React.FC<Props> = ({
    className: additionalClassName,
    leftAligned = false,
    children,
}) => {
    const className = createClassName(
        'intro',
        {
            'intro--left-aligned': leftAligned,
        },
        additionalClassName
    );

    return <div className={className}>{children}</div>;
};

export default Intro;
