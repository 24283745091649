import React from 'react';
import LayoutContainer from '../primitives/layout/LayoutContainer';
import Heading, {
    SizeVariationOptions,
    TagOptions,
} from '../primitives/heading/Heading';
import useFetchPagesWithIdentifiers from '../../hooks/useFetchPagesWithIdentifiers';
import { PageIdentifier } from '../../model/page';
import LinkButtonWithArrow from '../primitives/button/LinkButtonWithArrow';
import TextBlock from '../primitives/textBlock/TextBlock';
import TextBlockContent from '../primitives/textBlock/components/TextBlockContent';

const AboutIntroduction: React.FC = () => {
    const { pages, resolvePageWithIdentifier } = useFetchPagesWithIdentifiers();

    if (!pages) {
        return null;
    }

    const aboutPage = resolvePageWithIdentifier(PageIdentifier.About);

    return (
        <div className="slanted-container">
            <div className="slanted-container__line" />
            <LayoutContainer>
                <TextBlock>
                    <TextBlockContent className="text-block__content--about">
                        <Heading
                            tag={TagOptions.h2}
                            size={SizeVariationOptions.h1}
                        >
                            Hét handboek voor jouw succesvolle online
                            marktplaats
                        </Heading>
                        <Heading
                            tag={TagOptions.h3}
                            size={SizeVariationOptions.h4}
                        >
                            Over het boek
                        </Heading>
                        <p>
                            'Bouw een succesvolle online marktplaats’ is een
                            handboek. Dat wil zeggen: super praktisch. Je leest
                            de eerlijke ervaringen van oprichters van o.a.
                            Catawiki, Werkspot en Peerby, en van corporates als
                            Centraal Beheer. Je krijgt ‘huiswerk’ om jouw idee
                            steeds concreter te maken. En stap voor stap
                            doorloop je alle onmisbare fases van het bouwen van
                            een succesvolle marketplace; van eerste idee tot
                            jouw eigen goedlopende marktplaats.
                        </p>
                        <LinkButtonWithArrow
                            path={aboutPage._path}
                            label="Meer over het boek"
                        />
                    </TextBlockContent>
                </TextBlock>
            </LayoutContainer>
        </div>
    );
};

export default AboutIntroduction;
