import React from 'react';

type Props = {
    children: React.ReactNode;
};

const LayoutContainer = ({ children }: Props) => (
    <div className="container">{children}</div>
);

export default LayoutContainer;
