import React from 'react';

type Props = {
    children: React.ReactNode;
};

const Main = ({ children }: Props) => (
    <main className="main">{children}</main>
);

export default Main;
