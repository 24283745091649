import React, { MouseEventHandler } from 'react';
import createClassName from 'classnames';
import { pushTrackingEvent } from '@freshheads/javascript-essentials/build/utilities/dataLayer';

type Props = {
    isNav?: boolean;
};

const OrderBook: React.FC<Props> = ({ isNav = false }) => {
    const onClick: MouseEventHandler = () => {
        pushTrackingEvent('order', 'click', {
            fromMainNavigation: isNav,
        });
    };

    const className = createClassName('button', 'button--tertiary-highlight', {
        'main-nav__button': isNav,
        'hero__button button--big': !isNav,
    });

    return (
        <a
            className={className}
            onClick={onClick}
            href="https://managementboek.nl/boek/9789024434893/bouw-een-succesvolle-online-marktplaats-wout-withagen?affiliate=7209"
            target="_blank"
            rel="noreferrer"
        >
            Boek bestellen
        </a>
    );
};

export default OrderBook;
