import { useState } from 'react';

type Result = {
    showModal: boolean;
    doShowModal: () => void;
    doHideModal: () => void;
};

export default function useShowHideModal(startValue: boolean = false): Result {
    const [showModal, setShowModal] = useState<boolean>(startValue);

    const doShowModal = () => {
        setShowModal(true);
    };

    const doHideModal = () => {
        setShowModal(false);
    };

    return { showModal, doShowModal, doHideModal };
}
