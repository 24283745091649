import { pushTrackingEvent } from '../../../utilities/trackingUtilities';
import {
    getBoolean as getBooleanFromCookieStorage,
    write as writeToCookieStorage,
} from '@freshheads/javascript-essentials/build/storage/cookieStorage';

type Result = {
    cookieValue: boolean | null;
    onCookieConfirm: () => void;
    onCookieDecline: () => void;
};

const COOKIE_NAME = 'cookie_consent_acquired';

export default function useConfirmDeclineCookie(
    doHideModal: () => void
): Result {
    const cookieValue = getBooleanFromCookieStorage(COOKIE_NAME, null);

    const onCookieConfirm = () => {
        pushTrackingEvent('cookie_consent', 'allow');

        doHideModal();

        writeToCookieStorage(COOKIE_NAME, true);
    };

    const onCookieDecline = () => {
        doHideModal();

        writeToCookieStorage(COOKIE_NAME, false);
    };

    return { cookieValue, onCookieConfirm, onCookieDecline };
}
