import React from 'react';
import AuthorsContactLink from './components/AuthorsContactLink';
import AuthorsCompany from './components/AuthorsCompany';
import AuthorsName from './components/AuthorsName';
import AuthorsContainer from './components/AuthorsContainer';
import AuthorInfo from './components/AuthorInfo';
import AuthorContactOptions from './components/AuthorContactOptions';
import AuthorsImageHolder from './components/AuthorsImageHolder';
import TextCarousel from '../primitives/textCarousel/TextCarousel';
import ParallaxText from '../primitives/parallaxText/ParallaxText';
import AuthorsImage from './components/AuthorsImage';

export const TrackingEventName = 'authors_contact';

const AuthorsContactInformation: React.FC = () => (
    <>
        <ParallaxText speed={-2}>
            <TextCarousel text="De Auteurs" />
        </ParallaxText>
        <AuthorsContainer>
            <AuthorInfo position="left">
                <AuthorsName name="Wout Withagen" />
                <AuthorsCompany title="Mede-eigenaar Freshheads" />
                <AuthorContactOptions>
                    <AuthorsContactLink href="https://www.linkedin.com/in/woutwithagen/">
                        linkedin
                    </AuthorsContactLink>
                    <AuthorsContactLink href="https://twitter.com/woutwithagen">
                        twitter
                    </AuthorsContactLink>
                    <AuthorsContactLink href="mailto:wout.withagen@freshheads.com">
                        e-mail
                    </AuthorsContactLink>
                </AuthorContactOptions>
            </AuthorInfo>
            <AuthorsImageHolder>
                <AuthorsImage />
            </AuthorsImageHolder>
            <AuthorInfo position="right">
                <AuthorsName name="Joost Gielen" />
                <AuthorsCompany title="Oprichter Werkspot.nl" />
                <AuthorContactOptions>
                    <AuthorsContactLink href="https://www.linkedin.com/in/joostgielen/">
                        linkedin
                    </AuthorsContactLink>
                </AuthorContactOptions>
            </AuthorInfo>
        </AuthorsContainer>
    </>
);

export default AuthorsContactInformation;
