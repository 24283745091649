import React from 'react';

type Props = {
    title: string;
};

const AuthorsCompany: React.FC<Props> = ({ title }) => (
    <div className="authors__company">{title}</div>
);

export default AuthorsCompany;
