import React from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    hasError?: boolean;
};

const FormGroup = ({ children, hasError = false }: Props): JSX.Element => {
    const className = createClassName('form__group', {
        'has-error': hasError,
    });

    return <div className={className}>{children}</div>;
};

export default FormGroup;
