import React from 'react';
import NewsletterSubscriptionForm from './components/NewsletterSubscriptionForm';
import usePushNewsletterSubscriptionToServerOnFormValid, {
    Status,
} from './hooks/usePushNewsletterSubscriptionToServerOnFormValid';
import Message, { MessageType } from '../primitives/message/Message';
import LayoutContainer from '../primitives/layout/LayoutContainer';
import Heading, {
    TagOptions,
    SizeVariationOptions,
} from '../primitives/heading/Heading';
import useShowWithDelay from '../../hooks/useShowWithDelay';

export const TrackingEventName = 'newsletter_subscription';

const showDelay = 1000; // 1 second

const NewsletterSubscription: React.FC = () => {
    const {
        onValidSubmit,
        status,
    } = usePushNewsletterSubscriptionToServerOnFormValid();

    const visible = useShowWithDelay(showDelay);

    if (!visible) {
        return null;
    }

    return (
        <div className="newsletter-subscription-container">
            <LayoutContainer>
                <div className="newsletter-subscription">
                    <div className="newsletter-subscription__content">
                        <Heading
                            tag={TagOptions.h3}
                            size={SizeVariationOptions.h1}
                        >
                            Altijd op de hoogte van de laatste marketplace tips
                        </Heading>
                        <p>
                            Wil jij écht gas geven met het ontwikkelen van jouw
                            eigen succesvolle marktplaats? Meld je dan aan voor
                            de nieuwsbrief en ontvang elke maand een verse dosis
                            tips en inzichten!
                        </p>
                        {status === Status.Submitting && (
                            <Message type={MessageType.Notice}>
                                Aan het verzenden...
                            </Message>
                        )}
                        {status === Status.Success && (
                            <Message type={MessageType.Success}>
                                Bedankt voor je inschrijving!
                            </Message>
                        )}
                        {status === Status.Error && (
                            <Message type={MessageType.Error}>
                                Er is iets mis gegaan... Probeer het nog eens!
                            </Message>
                        )}
                        {(status === Status.Open ||
                            status === Status.Error) && (
                            <NewsletterSubscriptionForm
                                onValidSubmit={onValidSubmit}
                            />
                        )}
                    </div>
                </div>
            </LayoutContainer>
        </div>
    );
};

export default NewsletterSubscription;
