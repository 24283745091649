import { useQuery } from 'react-query';
import { PageSummary, PageIdentifier } from '../model/page';
import { AxiosError } from 'axios';
import { fetchAllPagesWithIdentifiers } from '../repository/pageRepository';
import {
    getFromBridge,
    BackendBridgeIdentifier,
} from '../repository/backendBridgeRepository';
import { isAbbortedRequestError } from '../api/utilities/errorIdentificationUtilities';

type PageResolver = (identifier: PageIdentifier) => PageSummary;

export type Result = {
    pages: PageSummary[] | undefined;
    isFetching: boolean;
    resolvePageWithIdentifier: PageResolver;
};

export default function useFetchPagesWithIdentifiers(): Result {
    const initialData = getFromBridge<PageSummary[]>(
        BackendBridgeIdentifier.PageIndexWithIdentifiers
    );

    const { data: pages, error, isFetching } = useQuery<
        PageSummary[],
        string,
        AxiosError
    >('pages', () => fetchAllPagesWithIdentifiers(), {
        retry: false,
        initialData,
        refetchOnMount: false,
        refetchOnWindowFocus: true,
    });

    const resolvePageWithIdentifier: PageResolver = (identifier) => {
        if (!pages) {
            throw new Error('Pages are still being fetched. Call afterwards!');
        }

        const page = pages.find(
            (cursorPage) => cursorPage.identifier === identifier
        );

        if (!page) {
            throw new Error(
                `Could not resolve path with identifier '${identifier}'`
            );
        }

        return page;
    };

    if (error) {
        if (!isAbbortedRequestError(error)) {
            throw Error;
        }
    }

    return { pages, isFetching, resolvePageWithIdentifier };
}
