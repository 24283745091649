import { Serializable } from '@freshheads/javascript-essentials/build/types/utility';
import axios, { Canceler, AxiosPromise } from 'axios';

export type CancelCallback = Canceler | null;

const defaultTimeoutLength = 4000; // 4 seconds

export function executeGetRequest<ResponseType>(
    path: string
): [AxiosPromise<ResponseType>, CancelCallback] {
    let cancel: CancelCallback = null;

    const cancelToken = new axios.CancelToken((c) => (cancel = c));

    const promise = axios.get<ResponseType>(path, {
        cancelToken,
        timeout: defaultTimeoutLength,
    });

    return [promise, cancel];
}

export function executePostRequest<ResponseType>(
    path: string,
    data: Serializable = {}
): [AxiosPromise<ResponseType>, CancelCallback] {
    let cancel: CancelCallback = null;

    const cancelToken = new axios.CancelToken((c) => (cancel = c));

    const promise = axios.post(path, data, {
        cancelToken,
        timeout: defaultTimeoutLength,
    });

    return [promise, cancel];
}
