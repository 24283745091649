import React, { useEffect } from 'react';
import useShowHideModal from '../../hooks/useShowHideModal';
import useConfirmDeclineCookie from './hooks/useConfirmDeclineCookie';
import CookieConsentBanner from './components/CookieConsentBanner';

const GDPRThreshold: React.FC = () => {
    const { showModal, doShowModal, doHideModal } = useShowHideModal(false);
    const {
        cookieValue,
        onCookieConfirm,
        onCookieDecline,
    } = useConfirmDeclineCookie(doHideModal);

    useEffect(() => {
        if (typeof cookieValue !== 'boolean') {
            doShowModal();
        }
    }, [cookieValue, doShowModal]);

    if (showModal) {
        return (
            <div className="cookie__banner">
                <CookieConsentBanner
                    onConfirm={onCookieConfirm}
                    onDecline={onCookieDecline}
                />
            </div>
        );
    }

    return null;
};

export default GDPRThreshold;
