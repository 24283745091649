import React from 'react';
import { createHomePath } from '../../routing/urlGenerator/appUrlGenerator';
import { Helmet } from 'react-helmet';
import { composePageTitle } from '../../utilities/pageMetaGenerator';
import { Link } from 'react-router-dom';
import LayoutContainer from '../primitives/layout/LayoutContainer';
import Heading, { TagOptions } from '../primitives/heading/Heading';
import Intro from '../primitives/intro/Intro';
import IntroContent from '../primitives/intro/components/IntroContent';

const NotFound: React.FC = () => (
    <>
        <Helmet>
            <title>{composePageTitle('Pagina niet gevonden')}</title>
        </Helmet>

        <LayoutContainer>
            <Intro>
                <IntroContent>
                    <Heading tag={TagOptions.h1}>
                        Sorry, we kunnen deze pagina niet vinden
                    </Heading>
                    <p>
                        We hebben ons best gedaan, maar het lijkt erop dat deze
                        pagina niet (meer) bestaat of misschien verhuisd is. Je
                        kunt natuurlijk altijd terug naar de{' '}
                        <Link to={createHomePath()}>homepage</Link>.
                    </p>
                </IntroContent>
            </Intro>
        </LayoutContainer>
    </>
);

export default NotFound;
