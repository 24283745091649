import React from 'react';
import { Waypoint } from 'react-waypoint';
import classnames from 'classnames';
import useSetRellax from '../../../hooks/useSetRellax';

const DEFAULT_SPEED = -1;

type Props = {
    children: React.ReactNode;
    speed: number;
    isSection?: boolean;
};

const ParallaxText: React.FC<Props> = ({
    children,
    speed = DEFAULT_SPEED,
    isSection = false,
}) => {
    const { elementRef, handleEnter, handleLeave } = useSetRellax(speed);

    const classes = classnames('parallax__holder', {
        'parallax__holder--section': isSection,
    });

    return (
        <div className="parallax" ref={elementRef}>
            <Waypoint
                onEnter={handleEnter}
                onLeave={handleLeave}
                bottomOffset={-200}
                topOffset={-200}
            >
                <div className={classes}>{children}</div>
            </Waypoint>
        </div>
    );
};

export default ParallaxText;
