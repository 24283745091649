import { useEffect } from 'react';

const escapeKeyCode = 27;

export default function useCloseOnEscapeKeyboardBindingPressed(
    enabled: boolean,
    close: () => void
): void {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        const onKeyDown = (event: KeyboardEvent) => {
            if (event.keyCode === escapeKeyCode) {
                close();
            }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => document.removeEventListener('keydown', onKeyDown);
    }, [enabled, close]);
}
