import React from 'react';
import LayoutContainer from '../primitives/layout/LayoutContainer';
import Intro from '../primitives/intro/Intro';
import IntroContent from '../primitives/intro/components/IntroContent';

const HomepageIntro: React.FC = () => (
    <div className="homepage-intro homepage-intro--before-live">
        <LayoutContainer>
            <Intro>
                <IntroContent>
                    <p>
                        Dit boek neemt je mee langs alle fases van het bouwen
                        van een succesvolle marketplace. Dus heb jij een goed
                        idee voor de nieuwe Airbnb van jouw markt? Dan is ‘Bouw
                        een succesvolle online marktplaats’ het boek voor jou!
                    </p>
                </IntroContent>
            </Intro>
        </LayoutContainer>
    </div>
);

export default HomepageIntro;
