import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

export default function useShowWithDelay(delay: number): boolean {
    const [visible, setVisible] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
        setVisible(false);

        const handle = setTimeout(() => setVisible(true), delay);

        return () => clearTimeout(handle);
    }, [delay, location.pathname]);

    return visible;
}
