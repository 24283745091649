import {
    generateRoute,
    extractPathWithPlaceholders,
} from '../utility/urlGenerator';

export const createHomePath = (): string => generateRoute('frontend_home');

export const createContactPath = (): string =>
    generateRoute('frontend_contact');

export const createPageDetailPath = (slug?: string): string => {
    const routeName = 'frontend_page_detail';

    if (!slug) {
        return extractPathWithPlaceholders(routeName);
    }

    return generateRoute(routeName, { slug });
};
