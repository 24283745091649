import React from 'react';
import NewsletterSubscriptionSubmitButton from './NewsletterSubscriptionSubmitButton';
import FormGroup from '../../primitives/form/FormGroup';
import FormTextField from '../../primitives/form/FormTextField';
import useNewsletterSubscriptionFormHandlers from '../hooks/useNewsletterSubscriptionFormHandlers';

export type OnValidSubmitHandler = (value: FormValues) => void;

export type FormValues = {
    emailAddress: string;
};

type Props = {
    onValidSubmit: OnValidSubmitHandler;
};

const NewsletterSubscriptionForm: React.FC<Props> = ({ onValidSubmit }) => {
    const {
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
    } = useNewsletterSubscriptionFormHandlers(onValidSubmit);

    return (
        <form onSubmit={handleSubmit}>
            <div className="newsletter-subscription__fake-form-field">
                <FormGroup
                    hasError={touched.emailAddress && !!errors.emailAddress}
                >
                    <FormTextField<FormValues>
                        id="emailAddress"
                        name="emailAddress"
                        onChange={handleChange}
                        value={values.emailAddress}
                        placeholder="E-mailadres"
                    />
                </FormGroup>
                <NewsletterSubscriptionSubmitButton />
            </div>
        </form>
    );
};

export default NewsletterSubscriptionForm;
