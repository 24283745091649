export enum PageIdentifier {
    About = 'about',
    PrivacyStatement = 'privacy_statement',
    Downloads = 'downloads',
}

export interface PageSummary {
    id: number;
    title: string;
    identifier: string;
    slug: string;
    _path: string;
}

export interface PageDetail extends PageSummary {
    introduction: string | null;
    description: string;
}
