// Beware! Keep these identifiers in line with backend setup (ApiResponsePreloadStore.php)
export enum BackendBridgeIdentifier {
    PageDetail = 'page_detail',
    PageIndexWithIdentifiers = 'page_index_with_identifiers',
}

export function getFromBridge<T>(
    identifier: BackendBridgeIdentifier
): T | undefined {
    // @ts-ignore Typescript does not know __bridge
    if (typeof __bridge === 'undefined') {
        return undefined;
    }

    // @ts-ignore Typescript does not know __bridge
    return __bridge[identifier] || undefined;
}
