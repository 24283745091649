import React from 'react';
import LayoutContainer from '../components/primitives/layout/LayoutContainer';
import Heading, {
    TagOptions,
    SizeVariationOptions,
} from '../components/primitives/heading/Heading';
import useFetchPagesWithIdentifiers from '../hooks/useFetchPagesWithIdentifiers';
import { PageIdentifier } from '../model/page';
import LinkButtonWithArrow from '../components/primitives/button/LinkButtonWithArrow';
import TextBlock from '../components/primitives/textBlock/TextBlock';
import TextBlockContent from '../components/primitives/textBlock/components/TextBlockContent';

const AuthorsIntroduction: React.FC = () => {
    const { pages, resolvePageWithIdentifier } = useFetchPagesWithIdentifiers();

    if (!pages) {
        return null;
    }

    const aboutPage = resolvePageWithIdentifier(PageIdentifier.About);

    return (
        <LayoutContainer>
            <TextBlock>
                <TextBlockContent>
                    <Heading tag={TagOptions.h2} size={SizeVariationOptions.h1}>
                        Twee marketplace-deskundigen delen hun lessen
                    </Heading>
                    <p>
                        In dit boek delen ondernemers Wout Withagen en Joost
                        Gielen het enige echte stappenplan voor het bouwen van
                        een succesvol online platform. Als oprichters van
                        Werkspot, bouwers van vele online marktplaatsen en
                        eigenaren van digitaal innovatiebureau Freshheads worden
                        zij regelmatig om advies gevraagd bij het concreet maken
                        van een marktplaats-idee. Met dit boek geven ze dat
                        advies. Alle lessen, successen en valkuilen van een
                        dikke vijftien jaar marktplaats-ervaring komen samen in
                        hét handboek over marketplaces.
                    </p>
                    <LinkButtonWithArrow
                        path={aboutPage._path}
                        label="Meer over de auteurs"
                    />
                </TextBlockContent>
            </TextBlock>
        </LayoutContainer>
    );
};

export default AuthorsIntroduction;
