import { useRef, useState } from 'react';
import Rellax from 'rellax';

export default function useSetRellax(speed: number) {
    const [rellax, setRellax] = useState<Rellax.RellaxInstance | null>(null);
    const elementRef = useRef<HTMLDivElement>(null);

    const handleEnter = () => {
        if (!elementRef.current) {
            return;
        }

        const parallaxHolder = elementRef.current.querySelector(
            '.parallax__holder'
        );

        if (!parallaxHolder) {
            return;
        }

        setRellax(
            new Rellax(parallaxHolder, {
                speed: speed,
                center: true,
                round: true,
                vertical: true,
                horizontal: false,
            })
        );
    };

    const handleLeave = () => {
        if (rellax) {
            rellax.destroy();

            setRellax(null);
        }
    };

    return { elementRef, handleEnter, handleLeave };
}
