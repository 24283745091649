import React from 'react';
import HomepageIntro from '../homepageIntro/HomepageIntro';
import Hero from '../hero/Hero';
import AboutIntroduction from '../aboutIntroduction/AboutIntroduction';
import AuthorsContactInformation from '../authorsContactInformation/AuthorsContactInformation';
import AuthorsIntroduction from '../../authorsIntroduction/AuthorsIntroduction';
import OrderBook from '../orderBook/OrderBook';
import BookPreview from '../bookPreview/BookPreview';

const Home: React.FC = () => (
    <>
        <Hero>
            <OrderBook />
        </Hero>
        <HomepageIntro />
        <AboutIntroduction />
        <AuthorsContactInformation />
        <AuthorsIntroduction />
        <BookPreview />
    </>
);

export default Home;
