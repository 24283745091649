import React, { Suspense, lazy } from 'react';

const PageDetailLoader: React.FC = lazy(() =>
    import(
        /* webpackChunkName: "page_detail", webpackPrefetch: true */ './PageDetail'
    )
);

const LazyLoadingPageDetail: React.FC = () => (
    <Suspense fallback="">
        <PageDetailLoader />
    </Suspense>
);

export default LazyLoadingPageDetail;
