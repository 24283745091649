import React from 'react';
import BookExampleOg from '../../../../../images/book-example-og.jpg';
import { Helmet } from 'react-helmet';
import {
    rootSeoDescription,
    rootSeoTitle,
} from '../../../utilities/pageMetaGenerator';

const GlobalMeta: React.FC = () => {
    const ogImageUrl = `${window.location.protocol}//${window.location.host}${BookExampleOg}`;

    return (
        <Helmet>
            <title>{rootSeoTitle}</title>

            {/* General meta */}
            <meta name="title" content={rootSeoTitle} />
            <meta name="description" content={rootSeoDescription} />

            {/* Open Graph meta */}
            <meta property="og:locale" content="nl_NL" />
            <meta property="og:type" content="books.book" />
            <meta property="og:title" content={rootSeoTitle} />
            <meta property="og:site_name" content={rootSeoTitle} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:description" content={rootSeoDescription} />
            <meta property="og:image" content={ogImageUrl} />
            <meta property="books:isbn" content="9789024434893" />
            <meta property="books:rating:value" content="" />
            <meta property="books:rating:scale" content="" />
            <meta property="books:author:first_name" content="Wout" />
            <meta property="books:author:last_name" content="Withagen" />
            <meta property="books:author:gender" content="male" />
            <meta property="books:author:first_name" content="Joost" />
            <meta property="books:author:last_name" content="Gielen" />
            <meta property="books:author:gender" content="male" />
        </Helmet>
    );
};

export default GlobalMeta;
