import React from 'react';

type Props = {
    children: React.ReactNode;
};

const IntroContent: React.FC<Props> = ({ children }) => (
    <div className="intro__content">{children}</div>
);

export default IntroContent;
