import React from 'react';
import useFetchPagesWithIdentifiers from '../../../hooks/useFetchPagesWithIdentifiers';
import { PageIdentifier } from '../../../model/page';
import { Link } from 'react-router-dom';

type Props = {
    onConfirm: () => void;
    onDecline: () => void;
};

const CookieConsentBanner: React.FC<Props> = ({
    onConfirm,
    onDecline,
}: Props) => {
    const { pages, resolvePageWithIdentifier } = useFetchPagesWithIdentifiers();

    if (!pages) {
        return null;
    }

    const privacyStatementPage = resolvePageWithIdentifier(
        PageIdentifier.PrivacyStatement
    );

    return (
        <div className="container">
            <div className="cookie__banner-content">
                <p>
                    Net zoals andere websites maken wij gebruik van cookies om
                    jouw browsing-ervaring te verbeteren. Met deze cookies (en
                    daarmee vergelijkbare technieken) passen wij en derde
                    partijen advertenties aan op basis van jouw interesses en
                    deel jij eenvoudig informatie via social media. Lees voor
                    meer informatie de{' '}
                    <Link to={privacyStatementPage._path}>
                        privacy statement
                    </Link>
                </p>
                <div className="button-wrapper">
                    <button
                        className="button button--anchor"
                        onClick={onDecline}
                    >
                        Weigeren
                    </button>
                    <button className="button" onClick={onConfirm}>
                        Accepteren
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsentBanner;
