import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import {
    createHomePath,
    createPageDetailPath,
    createContactPath,
} from '../routing/urlGenerator/appUrlGenerator';
import Home from './home/Home';
import NotFound from './notFound/NotFound';
import ErrorBoundary from '@freshheads/javascript-essentials/build/react/components/ErrorBoundary';
import ErrorInformation from './errorInformation/ErrorInformation';
import Main from './primitives/layout/Main';
import Header from './primitives/header/Header';
import { logError } from '../utilities/logging';
import GlobalMeta from './primitives/meta/GlobalMeta';
import GDPRThreshold from './gdprThreshold/GDPRThreshold';
import useScrollToTopOnDependencyChange from '@freshheads/javascript-essentials/build/react/hooks/useScrollToTopOnDependencyChange';
import LazyLoadingPageDetail from './pageDetail/LazyLoadingPageDetail';
import LazyLoadingContact from './contact/LazyLoadingContact';
import Footer from './primitives/footer/Footer';
import NewsletterSubscription from './newsletterSubscription/NewsletterSubscription';

const App: React.FC = () => {
    const location = useLocation();

    useScrollToTopOnDependencyChange(location.pathname);

    return (
        <ErrorBoundary
            renderFallback={(error, errorInfo) => (
                <ErrorInformation error={error} errorInfo={errorInfo} />
            )}
            onErrorOccurred={(error, errorInfo) => {
                logError(error, { ...errorInfo });

                console.log('log', error, errorInfo);
            }}
        >
            <GlobalMeta />
            <Header />
            <GDPRThreshold />
            <Main>
                <Switch>
                    <Route path={createHomePath()} exact>
                        <Home />
                    </Route>
                    <Route path={createContactPath()} exact>
                        <LazyLoadingContact />
                    </Route>
                    <Route path={createPageDetailPath()} exact>
                        <LazyLoadingPageDetail />
                    </Route>
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
                <NewsletterSubscription />
            </Main>
            <Footer />
        </ErrorBoundary>
    );
};

export default App;
