import React from 'react';
import BookExampleOpen from '../../../../../images/book-example-open.jpg';

const BookPreviewImage: React.FC = () => (
    <img
        src={BookExampleOpen}
        alt="Bouw een succesvolle online marktplaats"
        className="book-preview__image"
    />
);

export default BookPreviewImage;
