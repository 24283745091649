import React from 'react';
import { ReactComponent as QuoteIcon } from '../../../../../images/icons/quote.svg';

type Props = {
    children: React.ReactNode;
    author: string;
    company: string;
};

const QuotesItem: React.FC<Props> = ({ children, author, company }) => (
    <div className="quotes__item">
        <QuoteIcon className="quotes__icon" />
        <div className="quotes__text">{children}</div>
        <div className="quotes__author">
            <div className="quotes__author-name">{author}</div>
            <div className="quotes__author-company">{company}</div>
        </div>
    </div>
);

export default QuotesItem;
