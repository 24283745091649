import React from 'react';
import BookExample from '../../../../images/book-example.png';
import Quotes from '../quotes/Quotes';
import { ReactComponent as Sticker } from '../../../../images/icons/luisterboek.svg';

type Props = {
    children: React.ReactNode;
};

const Hero: React.FC<Props> = ({ children }) => (
    <div className="hero-container">
        <div className="hero">
            <div className="hero__content">
                <div className="hero__image-holder">
                    <img
                        src={BookExample}
                        alt="Bouw een succesvolle online marktplaats"
                        className="hero__image"
                    />
                    <a
                        href="https://managementboek.nl/luisterboek/9789462554313/bouw-een-succesvolle-online-marktplaats-wout-withagen"
                        target="_blank"
                        rel="noreferrer nofollow"
                    >
                        <Sticker className="hero__sticker" />
                    </a>
                </div>
                <Quotes />
            </div>
            <div className="hero__button-container">{children}</div>
        </div>
    </div>
);

export default Hero;
