import { useRef } from 'react';

export default function useHandleTextCarouselScroll() {
    const DEFAULT_TRANSFORMAT_FACTOR = 7;
    const elementRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (!elementRef.current) {
            return;
        }

        const translateX =
            (elementRef.current.getBoundingClientRect().bottom /
                window.innerHeight) *
            DEFAULT_TRANSFORMAT_FACTOR;

        elementRef.current.style.transform = `translate3d(-${translateX}%, 0, 0)`;
    };

    const handleEnter = () => {
        document.addEventListener('scroll', handleScroll);
    };

    const handleLeave = () => {
        document.removeEventListener('scroll', handleScroll);
    };

    return { elementRef, handleEnter, handleLeave };
}
