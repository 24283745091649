enum Environment {
    dev = 'dev',
    production = 'production',
}

export const currentEnvironment: string =
    process.env.NODE_ENV || Environment.production;

export const isDevEnvironment = currentEnvironment === Environment.dev;
export const isProductionEnvironment =
    currentEnvironment === Environment.production;
