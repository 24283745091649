import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import createClassName from 'classnames';

type Props = {
    path: string;
    title: string;
    onClick: () => void;
};

const MainNavigationItem: React.FC<Props> = ({ path, title, onClick }) => {
    const location = useLocation();

    const className = createClassName('main-nav__link main-nav__link', {
        'main-nav__link main-nav__link--active': location.pathname === path,
    });

    return (
        <li className="main-nav__item">
            <Link to={path} className={className} onClick={onClick}>
                {title}
            </Link>
        </li>
    );
};

export default MainNavigationItem;
