import React from 'react';

type Props = {
    onClick: () => void;
};

const MainNavigationHamburgerButton: React.FC<Props> = ({ onClick }) => (
    <button className="main-nav-trigger" onClick={onClick}>
        <span className="main-nav-trigger__hamburger">
            <span className="main-nav-trigger__hamburger-line" />
            <span className="main-nav-trigger__hamburger-line" />
            <span className="main-nav-trigger__hamburger-line" />
        </span>
        menu
    </button>
);

export default MainNavigationHamburgerButton;
