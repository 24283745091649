import React, { MouseEventHandler } from 'react';
import { Serializable } from '@freshheads/javascript-essentials/build/types/utility';
import createClassName from 'classnames';
import { pushTrackingEvent } from '../../../utilities/trackingUtilities';

type Props = {
    className?: string;
    children: React.ReactNode;
    trackEvent: string;
    trackData?: Serializable;
};

const FormSubmitButton: React.FC<Props> = ({
    className: additionalClassName,
    children,
    trackEvent,
    trackData,
}) => {
    const className = createClassName(
        'button button--big',
        additionalClassName
    );

    const onClick: MouseEventHandler = () => {
        pushTrackingEvent(trackEvent, 'submit', trackData);
    };

    return (
        <button type="submit" className={className} onClick={onClick}>
            {children}
        </button>
    );
};

export default FormSubmitButton;
