import React from 'react';
import BookPreviewLink from './components/BookPreviewLink';
import BookPreviewButton from './components/BookPreviewButton';
import BookPreviewImage from './components/BookPreviewImage';

const BookPreview: React.FC = () => (
    <BookPreviewLink>
        <BookPreviewButton />
        <div className="book-preview__image-holder">
            <BookPreviewImage />
        </div>
    </BookPreviewLink>
);

export default BookPreview;
