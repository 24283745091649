import React from 'react';
import createClassName from 'classnames';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const TextBlockContent: React.FC<Props> = ({
    children,
    className: additionalClassName,
}) => {
    const className = createClassName(
        'text-block__content',
        additionalClassName
    );

    return <div className={className}>{children}</div>;
};

export default TextBlockContent;
