import { isDevEnvironment } from './environmentDetector';
import { Serializable } from '@freshheads/javascript-essentials/build/types/utility';
import { createNamespacedLogger } from '@freshheads/javascript-essentials/build/utilities/logger';
import { pushTrackingEvent as pushTrackingEventToDataLayer } from '@freshheads/javascript-essentials/build/utilities/dataLayer';

const logger = createNamespacedLogger('tracking');

export function pushTrackingEvent(
    event: string,
    action: string,
    context?: Serializable
): void {
    pushTrackingEventToDataLayer(event, action, context);

    if (isDevEnvironment) {
        logger.debug(event, action, context);
    }
}
