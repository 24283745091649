import React from 'react';
import createClassName from 'classnames';

export enum MessageType {
    Success,
    Error,
    Notice,
}

type Props = React.HTMLAttributes<HTMLParagraphElement> & {
    children: React.ReactNode;
    type: MessageType;
};

const Message: React.FC<Props> = ({
    children,
    type,
    className: additionalClassName,
    ...otherProps
}) => {
    const className = createClassName(
        'message',
        {
            'message--error': type === MessageType.Error,
            'message--success': type === MessageType.Success,
            'message--notice': type === MessageType.Notice,
        },
        additionalClassName
    );

    return (
        <p {...otherProps} className={className}>
            {children}
        </p>
    );
};

export default Message;
