import React, { ErrorInfo } from 'react';
import { composePageTitle } from '../../utilities/pageMetaGenerator';
import { Helmet } from 'react-helmet';
import { isDevEnvironment } from '../../utilities/environmentDetector';
import LayoutContainer from '../primitives/layout/LayoutContainer';
import Heading, {
    TagOptions,
    SizeVariationOptions,
} from '../primitives/heading/Heading';
import IntroContent from '../primitives/intro/components/IntroContent';
import Intro from '../primitives/intro/Intro';

type Props = {
    error: Error;
    errorInfo: ErrorInfo;
};

const ErrorInformation: React.FC<Props> = ({ error, errorInfo }) => (
    <>
        <Helmet>
            <title>{composePageTitle('Error!')}</title>
        </Helmet>
        <LayoutContainer>
            <Intro leftAligned>
                <IntroContent>
                    <Heading tag={TagOptions.h1}>
                        {isDevEnvironment
                            ? `Error: ${error.message}`
                            : 'Something went wrong!'}
                    </Heading>
                    {isDevEnvironment && (
                        <>
                            <Heading
                                tag={TagOptions.h2}
                                size={SizeVariationOptions.h3}
                            >
                                Stack trace
                            </Heading>
                            <pre>{errorInfo.componentStack}</pre>
                        </>
                    )}
                </IntroContent>
            </Intro>
        </LayoutContainer>
    </>
);

export default ErrorInformation;
