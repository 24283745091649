import React from 'react';
import LinkButton from './LinkButton';

type Props = {
    path: string;
    label: string;
};

const LinkButtonWithArrow: React.FC<Props> = ({ path, label }) => (
    <LinkButton path={path} anchor className="button--with-arrow">
        {label}
        <div className="button__arrow arrow">
            <i className="arrow__line" />
            <i className="arrow__head" />
        </div>
    </LinkButton>
);

export default LinkButtonWithArrow;
