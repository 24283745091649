import React, { MouseEventHandler } from 'react';
import { pushTrackingEvent } from '../../../utilities/trackingUtilities';
import { TrackingEventName } from '../AuthorsContactInformation';

type Props = {
    href: string;
    children: string;
};

const AuthorsContactLink: React.FC<Props> = ({ href, children }) => {
    const onClick: MouseEventHandler = () => {
        pushTrackingEvent(TrackingEventName, 'click', {
            href,
        });
    };

    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="authors__contact-link"
            onClick={onClick}
        >
            {children}
        </a>
    );
};

export default AuthorsContactLink;
