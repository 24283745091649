import React from 'react';
import { Waypoint } from 'react-waypoint';
import useHandleTextCarouselScroll from '../../../hooks/useHandleTextCarouselScroll';

type Props = {
    text: string;
};

const TextCarousel: React.FC<Props> = ({ text }) => {
    const {
        elementRef,
        handleEnter,
        handleLeave,
    } = useHandleTextCarouselScroll();

    return (
        <Waypoint onEnter={handleEnter} onLeave={handleLeave}>
            <div className="parallax__inner" ref={elementRef}>
                <h2>{text}</h2>
            </div>
        </Waypoint>
    );
};

export default TextCarousel;
