import React from 'react';
import { Link } from 'react-router-dom';
import useFetchPagesWithIdentifiers from '../../hooks/useFetchPagesWithIdentifiers';
import { PageIdentifier } from '../../model/page';
import { createContactPath } from '../../routing/urlGenerator/appUrlGenerator';

const FooterNavigation: React.FC = () => {
    const { pages, resolvePageWithIdentifier } = useFetchPagesWithIdentifiers();

    if (!pages) {
        return null;
    }

    const privacyStatementPage = resolvePageWithIdentifier(
        PageIdentifier.PrivacyStatement
    );

    return (
        <ul className="footer-nav">
            <li className="footer-nav__item">
                <Link to={createContactPath()} className="footer-nav__link">
                    Contact
                </Link>
            </li>
            <li className="footer-nav__item">
                <Link
                    to={privacyStatementPage._path}
                    className="footer-nav__link"
                >
                    {privacyStatementPage.title}
                </Link>
            </li>
        </ul>
    );
};

export default FooterNavigation;
