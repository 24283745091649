import React from 'react';
import Slider from 'react-slick';
import QuotesItem from './components/QuotesItem';

const Quotes: React.FC = () => (
    <Slider
        dots
        infinite
        arrows={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        className="quotes"
    >
        <QuotesItem author="Stefan Bary" company="Peak Capital">
            Het mooie is dat Wout en Joost niet een hoogover, wetenschappelijk,
            theoretisch framework neerzetten, maar heel pragmatisch op alle
            stapjes in de puzzel in gaan.
        </QuotesItem>
        <QuotesItem author="Martijn Arets" company="Platformexpert">
            Dit boek is een absolute aanrader voor iedereen die overweegt een
            eigen marktplaats te starten of meer wil leren over de dynamiek
            achter deze platformen.
        </QuotesItem>
        <QuotesItem
            author="René Schoenmakers"
            company="Oprichter Catawiki, Vipio en LastDodo"
        >
            Het boek biedt grip, inspiratie én een realitycheck voor ondernemers
            met marktplaats&shy;ambities.
        </QuotesItem>
    </Slider>
);

export default Quotes;
