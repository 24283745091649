import React from 'react';
import LayoutContainer from '../layout/LayoutContainer';
import FooterNavigation from '../../footerNavigation/FooterNavigation';
import useShowWithDelay from '../../../hooks/useShowWithDelay';
import { ReactComponent as FooterLogo } from '../../../../../images/icons/initiatief-van-fh.svg';

const showDelay = 1000; // 1 second

const Footer: React.FC = () => {
    const visible = useShowWithDelay(showDelay);

    if (!visible) {
        return null;
    }

    return (
        <footer className="footer-container">
            <LayoutContainer>
                <div className="footer">
                    <FooterNavigation />
                    <a
                        href="https://www.freshheads.com/?utm_source=besom&utm_medium=referral&utm_campaign=footer"
                        target="_blank"
                        rel="noreferrer"
                        className="footer__logo"
                    >
                        <FooterLogo />
                    </a>
                </div>
            </LayoutContainer>
        </footer>
    );
};

export default Footer;
