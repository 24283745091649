import React from 'react';
import { ReactComponent as EyeIcon } from '../../../../../images/icons/eye.svg';

const BookPreviewButton: React.FC = () => (
    <button className="book-preview__button button button--big">
        <EyeIcon className="button__icon" />
        Lees het eerste hoofdstuk
    </button>
);

export default BookPreviewButton;
