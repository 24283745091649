import React from 'react';
import {
    createHomePath,
    createContactPath,
} from '../../routing/urlGenerator/appUrlGenerator';
import MainNavigationItem from './components/MainNavigationItem';
import useFetchPagesWithIdentifiers from '../../hooks/useFetchPagesWithIdentifiers';
import { PageIdentifier } from '../../model/page';
import useToggleOpenClose from '../../hooks/useToggleOpenClose';
import createClassName from 'classnames';
import useCloseOnEscapeKeyboardBindingPressed from './hooks/useCloseOnEscapeKeyboardBindingPressed';
import MainNavigationHamburgerButton from './components/MainNavigationHamburgerButton';
import OrderBook from '../orderBook/OrderBook';

const MainNavigation: React.FC = () => {
    const { pages, resolvePageWithIdentifier } = useFetchPagesWithIdentifiers();

    const { isOpen, toggle, close } = useToggleOpenClose(false);

    useCloseOnEscapeKeyboardBindingPressed(isOpen, close);

    if (!pages) {
        return null;
    }

    const aboutPage = resolvePageWithIdentifier(PageIdentifier.About);

    const downloadsPage = resolvePageWithIdentifier(PageIdentifier.Downloads);

    const className = createClassName('main-nav-container', {
        'main-nav-container--is-open': isOpen,
    });

    return (
        <div className={className}>
            <MainNavigationHamburgerButton onClick={() => toggle()} />
            <ul className="main-nav">
                <MainNavigationItem
                    path={createHomePath()}
                    title="home"
                    onClick={() => close()}
                />
                <MainNavigationItem
                    path={aboutPage._path}
                    title="over"
                    onClick={() => close()}
                />
                <MainNavigationItem
                    path={downloadsPage._path}
                    title="downloads"
                    onClick={() => close()}
                />
                <MainNavigationItem
                    path={createContactPath()}
                    title="contact"
                    onClick={() => close()}
                />
            </ul>
            <OrderBook isNav />
        </div>
    );
};

export default MainNavigation;
