import React from 'react';
import LayoutContainer from '../../primitives/layout/LayoutContainer';

type Props = {
    children: React.ReactNode;
};

const AuthorsContainer: React.FC<Props> = ({ children }) => (
    <div className="authors-container">
        <LayoutContainer>
            <div className="authors">{children}</div>
        </LayoutContainer>
    </div>
);

export default AuthorsContainer;
