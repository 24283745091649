import { PageIndexApiResponse, PageDetailApiResponse } from './../api/response';
import {
    createPageIndexApiPath,
    createPageDetailApiPath,
} from '../routing/urlGenerator/apiUrlGenerator';
import { executeGetRequest } from '../api/client';
import { PageDetail, PageSummary } from '../model/page';

export async function fetchOneWithSlug(slug: string): Promise<PageDetail> {
    const path = createPageDetailApiPath(slug);

    const [promise] = executeGetRequest<PageDetailApiResponse>(path);

    return (await promise).data;
}

export async function fetchAllPagesWithIdentifiers(): Promise<PageSummary[]> {
    const path = createPageIndexApiPath(true);

    const [promise] = executeGetRequest<PageIndexApiResponse>(path);

    return (await promise).data;
}
