import React from 'react';
import createClassName from 'classnames';

export enum TagOptions {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    span = 'span',
}

export enum SizeVariationOptions {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    base = 'base',
}

export enum StyleOptions {
    base = 'base',
    brand = 'brand',
}

export enum WeightOptions {
    semiBold = 'semiBold',
    bold = 'bold',
    extraBold = 'extraBold',
}

interface Props {
    tag: TagOptions;
    style?: StyleOptions;
    weight?: WeightOptions;
    size?: SizeVariationOptions;
    uppercase?: boolean;
    flatten?: boolean;
    children: React.ReactNode;
    className?: string;
    hasAction?: boolean;
    [key: string]: unknown;
}

const Heading: React.FC<Props> = ({
    tag,
    style = StyleOptions.brand,
    weight = WeightOptions.bold,
    size,
    uppercase = false,
    flatten = false,
    children,
    className: incommingClassName,
    ...otherProps
}) => {
    const className: string = createClassName(
        'heading',
        {
            ['heading--uppercase']: uppercase,
            ['heading--flatten']: flatten,
            ['heading--base']: style === StyleOptions.base,
            ['heading--semi-bold']: weight === WeightOptions.semiBold,
            ['heading--extra-bold']: weight === WeightOptions.extraBold,
            ['heading--size-variant-h1']: size === SizeVariationOptions.h1,
            ['heading--size-variant-h2']: size === SizeVariationOptions.h2,
            ['heading--size-variant-h3']: size === SizeVariationOptions.h3,
            ['heading--size-variant-h4']: size === SizeVariationOptions.h4,
            ['heading--size-variant-h5']: size === SizeVariationOptions.h5,
            ['heading--size-variant-h6']: size === SizeVariationOptions.h6,
            ['heading--size-variant-base']: size === SizeVariationOptions.base,
        },
        incommingClassName
    );

    return React.createElement(tag, { className, ...otherProps }, children);
};

export default Heading;
