import { AxiosError } from 'axios';

export const isAxiosError = (error: Error): error is AxiosError => {
    // @ts-ignore Typescript does not know about axios error
    if (typeof error.isAxiosError === 'undefined') {
        return false;
    }

    const axiosError = error as AxiosError;

    return axiosError.isAxiosError;
};

export const isNotFoundResponseError = (error: Error): boolean =>
    isAxiosError(error) ? error.response?.status === 404 : false;

export const isAbbortedRequestError = (error: Error): boolean =>
    isAxiosError(error) ? error.request.status === 0 && !error.response : false;
